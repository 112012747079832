export default defineNuxtRouteMiddleware(() => {
  if (process.server) return

  const { isAuthenticated } = useAuth()

  if (isAuthenticated.value) {
    return navigateTo({
      name: 'index'
    })
  }
})
